import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

import InputMask from "react-input-mask";

import toast,  { Toaster } from "react-hot-toast";

import { NavBar } from "../components/NavBar";
import { ClientCard } from "../components/ClientCard";
import { Button } from "../components/Button";

import { useAuth } from "../hooks/useAuth";
import { useCards } from "../hooks/useCards";

import { database } from "../services/firebase";

import "../styles/register-client.scss";
import { handleCPF, handleCNPJ, handleEmail } from "../utils/utils";

export function RegisterClient() {
	const { user } = useAuth();
	const navigate = useNavigate();

	const uid = user?.id;

	const { cardData, handleDeleteClient } = useCards(uid);

	const [responsibleNameInput, setResponsibleNameInput] = useState("");
	const [responsibleCPFInput, setResponsibleCPFInput] = useState("");

	const [nameInput, setNameInput] = useState("");
	const [CPFInput, setCPFInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [serviceProvided, setServiceProvidedInput] = useState("");
	const [phoneInput, setPhoneInput] = useState("");
	const [valueInput, setValueInput] = useState("");

	const [isChecked, setIsChecked] = useState(false);
	const [isFinancialDataDisabled, setIsFinancialDataDisabled] = useState(false);

	const [editingClientId, setEditingClientId] = useState("");
	const [mask, setMask] = useState("");

	const isAnyFieldEmpty =
		!nameInput ||
		!CPFInput ||
		!emailInput ||
		!serviceProvided ||
		!phoneInput ||
		!valueInput;
	
	const isButtonDisabled = (isAnyFieldEmpty) || (!isChecked && (!responsibleNameInput || !responsibleCPFInput));

	const goToIssueReceipt = () => {
        navigate('/issue-receipt');
  	};

	
	const handleCPFChange = (event: { target: { value: string } }) => {
		setResponsibleCPFInput(event.target.value);
	};
	
	const handleCPFBlur = (value: string) => {
		const numericValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos para validação
		// Alterna a máscara entre CPF e CNPJ
		if (numericValue.length > 11) {
			setMask("99.999.999/9999-99"); 
			handleCNPJ(numericValue, toast);
		} else if (value.length === 0) {
			setMask("");
			handleCPF(numericValue, toast);
		} else {
			setMask("999.999.999-99");
			handleCPF(numericValue, toast);
		}
	};

	function checkGender(word: string) {
		const femaleSuffixes = ["a", "ção", "dade", "gem"];
		const maleSuffixes = ["o", "or", "ão", "mento"];
	
		for (let i = 0; i < femaleSuffixes.length; i++) {
		if (word.endsWith(femaleSuffixes[i])) {
			return "Female";
		}
		}
		
		for (let i = 0; i < maleSuffixes.length; i++) {
		if (word.endsWith(maleSuffixes[i])) {
			return "Male";
		}
		}
		
		return "Unidentified";
	};

	const handleCheckboxChange = () => {
		if (!isChecked) {
			setNameInput(responsibleNameInput);
			setCPFInput(responsibleCPFInput);
			setResponsibleNameInput("");
			setResponsibleCPFInput("");
		}
		setIsChecked(!isChecked);
		setIsFinancialDataDisabled(!isFinancialDataDisabled);
	};

	const handleAddClient = () => {
		// Verifique se todos os campos obrigatórios estão preenchidos
		if (isButtonDisabled) {
			toast.error("Preencha todos os campos obrigatórios.")
		  return;
		}
	  
		const clientsRef = database.ref(`clients/${uid}`);

		const gender = checkGender(serviceProvided)
		const description = `${gender === "Female" ? "à" : "ao"} ${serviceProvided.toLowerCase()} de ${nameInput} realizad${gender === "Female" ? "a" : "o"} em`
	  
		const clientData = {
		  responsibleName: isChecked ? nameInput : responsibleNameInput,
		  responsibleCPF: isChecked ? CPFInput : responsibleCPFInput,
		  name: nameInput,
		  cpf: CPFInput,
		  email: emailInput,
		  description: description,
		  phone: phoneInput,
		  value: valueInput,
		};
	  
		if (editingClientId) {
		  // Editando cliente existente
		  clientsRef
			.child(editingClientId)
			.update(clientData)
			.then(() => {
				toast.success("Cliente editado com sucesso");
			  console.log("Cliente editado com sucesso!");
			  setEditingClientId("");
			})
			.catch((error) => {
				toast.error("Erro ao editar cliente");
			  console.error("Erro ao editar cliente:", error);
			});
		} else {
		  // Adicionando novo cliente
		  clientsRef
			.push(clientData)
			.then(() => {
				toast.success("Cliente adicionado com sucesso");
			  console.log("Cliente adicionado com sucesso!");
			})
			.catch((error) => {
				toast.error("Erro ao adicionar cliente");
			  console.error("Erro ao adicionar cliente:", error);
			});
		}
	};
	
	const handleEditClient = (clientId: string) => {
		setEditingClientId(clientId);
		
		const selectedClient = cardData.find((client) => client.id === clientId);
		const regexService = new RegExp("(?:à|ao)\\s(.*?)\\s(?:de)", "g");
		const serviceMatch = regexService.exec(selectedClient?.description || "");
		const serviceProvided = serviceMatch ? serviceMatch[1] : "";
	
		setResponsibleNameInput(selectedClient?.responsibleName || "");
		setResponsibleCPFInput(selectedClient?.responsibleCPF || "");
		setNameInput(selectedClient?.name || "");
		setCPFInput(selectedClient?.cpf || "");
		setEmailInput(selectedClient?.email || "");
		setServiceProvidedInput(serviceProvided);
		setPhoneInput(selectedClient?.phone || "");
		setValueInput(selectedClient?.value || "");
	};

	const clearFields = () => {
		setResponsibleNameInput("");
		setResponsibleCPFInput("");
		setNameInput("");
		setCPFInput("");
		setEmailInput("");
		setServiceProvidedInput("");
		setPhoneInput("");
		setValueInput("");
		toast.success("Campos limpos com sucesso");
	};

	return (
		<div className="outerDiv">
			<header>
				<NavBar></NavBar>
			</header>
			<main>
				<div className="container-fluid text-center">
					<Toaster
						position="top-center"
						reverseOrder={true}
					/>
					<div className="col-xl-4 col-lg-3 col-md-2 mb-4 left-side">
						<h1><span>Cadast</span>rar cliente</h1>
					</div>
					<div className="row">
						<div
							className="col-sm-9 col-md-8 col-lg-6 col-xl-4 col-xxl-3 col-11 m-xl-3 m-4"
							id="client-data"
						>
							<div className="financial-data">
								<div className="financial-data-subtitle">
									<h2>
										<span>Respo</span>nsável financeiro
									</h2>
								</div>

								<FloatingLabel
									controlId="floatingInput"
									label="Nome completo"
								>
									<Form.Control
										type="text"
										placeholder="Joe Doe"
										value={responsibleNameInput}
										onChange={(event) =>
											setResponsibleNameInput(
												event.target.value
											)
										}
										disabled={isFinancialDataDisabled}
									/>
								</FloatingLabel>

								<FloatingLabel
									controlId="floatingInput"
									label="CPF/CNPJ"
								>
									<InputMask
										className="form-control"
										type="text"
										mask={mask}
										value={responsibleCPFInput}
										onChange={(event) => handleCPFChange(event)
										}
										onBlur={(event) => 
											handleCPFBlur(event.target.value)
										}
										disabled={isFinancialDataDisabled}
									></InputMask>
								</FloatingLabel>

								<Form.Check // prettier-ignore
									type="checkbox"
									id="default-checkbox"
									checked={isChecked}
									onChange={handleCheckboxChange}
									label="Responsável financeiro é o cliente?"
								/>
							</div>

							<div className="client-data-subtitle">
								<h2>
									<span>Client</span>e
								</h2>
							</div>
							<FloatingLabel
								controlId="floatingInput"
								label="Nome completo"
							>
								<Form.Control
									type="text"
									placeholder="Joe Doe"
									value={nameInput}
									onChange={(event) =>
										setNameInput(event.target.value)
									}
								/>
							</FloatingLabel>

							<FloatingLabel
								controlId="floatingInput"
								label="CPF/CNPJ"
							>
								<InputMask
									className="form-control"
									type="text"
									mask={mask}
									value={CPFInput}
									onChange={handleCPFChange
									}
									onBlur={(event) => 
										handleCPFBlur(event.target.value)
									}
								></InputMask>
							</FloatingLabel>

							<FloatingLabel
								controlId="floatingInput"
								label="Email"
							>
								<Form.Control
									type="text"
									placeholder="joe@doe.com"
									value={emailInput}
									onChange={(event) =>
										setEmailInput(event.target.value)
									}
									onBlur={(event) =>
										handleEmail(event.target.value, toast)
									}
								/>
							</FloatingLabel>

							<FloatingLabel
								controlId="floatingInput"
								label="Serviço prestado"
							>
								<Form.Control
									type="text"
									placeholder=""
									value={serviceProvided}
									onChange={(event) =>
										setServiceProvidedInput(event.target.value)
									}
								/>
							</FloatingLabel>

							<FloatingLabel
								controlId="floatingInput"
								label="Telefone"
							>
								<InputMask
									className="form-control"
									type="text"
									mask="(99) 99999-9999"
									value={phoneInput}
									onChange={(event) =>
										setPhoneInput(event.target.value)
									}
								></InputMask>
							</FloatingLabel>

							<InputGroup>
								<InputGroup.Text>R$</InputGroup.Text>
								<FloatingLabel
									controlId="floatingInput"
									label="Valor do serviço"
								>
									<Form.Control
										type="number"
										placeholder="250.00"
										value={valueInput}
										onChange={(event) =>
											setValueInput(event.target.value)
										}
									/>
								</FloatingLabel>
							</InputGroup>

							<Button style={{ marginTop: "2rem" }} onClick={handleAddClient} id="actionButton">Salvar</Button>
							<Button onClick={clearFields} isSecondary id="actionButton">Limpar campos</Button>
							<Button onClick={goToIssueReceipt} isSecondary id="actionButton">Ir para Emitir Recibo</Button>
						</div>

						<div className="col-md-12 col-lg-12 col-xl-7 col-xxl-7 col-12 m-xl-3 mt-4 client-cards">
							<div className="mb-4">
								<h2>Cards dos clientes</h2>
							</div>

							<div className="cards">
								{cardData.map((client) => (
									<ClientCard 
										key={client.id} 
										client={client}
										valueFirstButton="Editar"
										onUse={() => handleEditClient(client.id)}
										onDelete={() => handleDeleteClient(client.id)} 
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}
