import { isCpf, isCnpj } from "validator-brazil";


export const handleCPF = (value: string, toast: { error: (arg0: string) => void; }) => {
    if (!isCpf(value)) {
        toast.error("CPF inválido.")
    }
};

export const handleCNPJ = (value: string, toast: { error: (arg0: string) => void; }) => {
    if (!isCnpj(value)) {
        toast.error("CNPJ inválido.")
    }
};

export const handleEmail = (value: string, toast: { error: (arg0: string) => void; }) => {
    const reg = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    
    if (!reg.test(value)) {
        toast.error("Email inválido.")
    }
};

export const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}/${month}/${date}`;
}

export const getExpiryDate = (dateInput: Date) => {
    const month = dateInput.getMonth() + 2;
    const year = dateInput.getFullYear();
    const date = dateInput.getDate();
    return `${year}/${month}/${date}`;
}